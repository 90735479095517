import React from 'react';
// Modules
import { NextPage } from 'next/types';
// Components
import NotFound from '../components/general/NotFound';
import Page from '../components/layout/Page';

const NotFoundPage: NextPage = () => (
  <Page>
    <NotFound />
  </Page>
);

export default NotFoundPage;
